
// do not export. Use the static function instead which identifies leap years
const DAYS_IN_MONTH = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
export const DAY_NAMES: string[] = [ 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su' ];
export const MONTH_NAMES: string[] = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];

export enum Month {
    January = 0,
    February = 1,
    March = 2,
    April = 3,
    May = 4,
    June = 5,
    July = 6,
    August = 7,
    September = 8,
    October = 9,
    November = 10,
    December = 11
}

export enum Weekday {
    Monday = 0,
    Tuesday = 1,
    Wednesday = 2,
    Thursday = 3,
    Friday = 4,
    Saturday = 5,
    Sunday = 6
}

export class SimpleDate {

    public readonly d: number;
    public readonly m: number;
    public readonly y: number; 

    /**
     * Create a date. No validation of the data takes place
     * @param d between 1 for the first day of the month and the last days of the respective month
     * @param m between January (0) and December (11) Use the Month enum where possible
     * @param y the year as a positive number.
     */
    constructor(d: number, m: number, y: number) {
        this.d = d;
        this.m = m;
        this.y = y;
    }

    public isBefore(date: SimpleDate): boolean {
        if(!date) return false;
        if(this.y < date.y) return true;
        else if(this.y === date.y) {
            if(this.m < date.m) return true;
            else if(this.m === date.m) {
                if(this.d < date.d) return true;
            }
        }
        return false;
    }

    public isAfter(date: SimpleDate): boolean {
        if(!date) return false;
        if(this.y > date.y) return true;
        else if(this.y === date.y) {
            if(this.m > date.m) return true;
            else if(this.m === date.m) {
                if(this.d > date.d) return true;
            }
        }
        return false;
    }

    public next(): SimpleDate {
        let d: number = this.d + 1;
        let m: number = this.m;
        let y: number = this.y;
        if(d > SimpleDate.daysInMonth(m, y)) {
            d = 1; // reset date
            m = m + 1; // increment month
            if(m > Month.December) {
                m = Month.January; // reset month
                y = y + 1; // increment year
            }
        }
        return new SimpleDate(d, m, y);
    }

    public equals(date: SimpleDate): boolean {
        if(!date) return false;
        return this.y === date.y && this.m === date.m && this.d === date.d;
    }

    /**
	 * Julian day number (JND)
	 * @return the <code>int</code> Julian day number
	 * http://www.cs.utsa.edu/~cs1063/projects/Spring2011/Project1/jdn-explanation.html
	 */
    jdn(): number {
		const a: number = Math.floor((14 - this.m - 1) / 12);
		const y: number = this.y + 4800 - a;
        const m: number = this.m + 1 + 12 * a - 3;
        const jdn: number = this.d + Math.floor((153*m + 2)/5) + 365*y + Math.floor(y/4) - Math.floor(y/100) + Math.floor(y/400) - 32045;
		return jdn;
    }
	
	/**
	 * The IDO weekday is calculated via the Julian Day Number (JDN)
	 * 
	 * @return 0 (Monday), 1 Tuesday, ..., 6 (Sunday)
	 */
	public isoWeekDay(): number {
		return this.jdn() % 7;
	}
	
	/**
	 * Calculates the difference between two dates using the Julian day number (JND).
	 * @param other
	 * @return
	 */
	public diff(other: SimpleDate): number {
		return other.jdn() - this.jdn();
    }

    /**
     * https://en.wikipedia.org/wiki/Leap_year
     * @param year 
     */
    public static isLeapYear(year: number): boolean {
        if(year % 4 !== 0) return false; // common year
        else if(year % 100 !== 0) return true; // leap year
        else if(year % 400 !== 0) return false; // common year
        else return true;
    }
    
    public static daysInMonth(month: number, year: number): number {
		return (month === Month.February && this.isLeapYear(year)) ?  29 : DAYS_IN_MONTH[month];
    }

    public static isValid(date: number, month: Month, year: number): boolean {
        if(!(date && month && year)) return false;
        else {
            return year > 0 && month >= Month.January && month <= Month.December && date > 0 && date <= this.daysInMonth(month, year);
        }
    }

    public toString(): string {
        return DAY_NAMES[this.isoWeekDay()] + ', ' +  this.d + ' ' + MONTH_NAMES[this.m] + ' ' + this.y;
    }

    /**
     * YYYY-MM-DD
     * @returns 
     */
    public isoDateString(): string {
        return this.y.toFixed() + '-' + ('0' + (this.m + 1).toFixed()).slice(-2) + '-' + ('0' + this.d.toFixed()).slice(-2);
    }

    /**
     * ISO data YYYY-MM-DD
     */
    static readonly regExDate: RegExp = /(\d{4})-(\d{2})-(\d{2})/i;

    public static parseIsoDate(isoDateString: string): SimpleDate {
        const regex: RegExpExecArray = SimpleDate.regExDate.exec(isoDateString);
        if(regex && regex.length > 3) {
            const y: number = parseInt(regex[1]);
            const m: number = parseInt(regex[2]) - 1;
            const d: number = parseInt(regex[3]);
            return new SimpleDate(d, m, y);
        } else return null;
    }

    public add(days: number): SimpleDate {
        let d: number = this.d + days;
        let m: number = this.m;
        let y: number = this.y;
        if(days > 0) {
            let dim: number = SimpleDate.daysInMonth(m, y);
            while(d > dim) {
                d -= dim;
                m += 1; // next month
                if(m > Month.December) {
                    m = Month.January; // reset month
                    y += 1; // next year
                }
                dim = SimpleDate.daysInMonth(m, y);
            }
        } else {
            while(d < 1) {
                m = m - 1; // previous month
                if(m < Month.January) {
                    m = Month.December; // reset month
                    y -= 1; // decrement year
                }
                d += SimpleDate.daysInMonth(m, y);
            }
        }
        return new SimpleDate(d, m, y);
    }

}