import { SimpleDate, DAY_NAMES, MONTH_NAMES, Month } from './simpledate';

const ABORT_TIME_MILLIES: number = 30000; // allow 30 seconds
const INITIAL_DELAY: number = 3000; // delay before attemting a fetch

export interface Config {
    containerId: string; // container ID for this calendar, notmally a <div>
    first?: string; // first displayed ISO date YYYY-MM-DD
    remote?: string; // the URL with the remove availability
    onStartDate?: (isoDateString: string) => void;
    onNights?: (nights: number) => void;
    classPrefix?: string;
    debug?: boolean;
}

export interface Availability {
    date: string; // ISO date YYYY-MM-DD
    avail: boolean;
    minNights?: number;
    rate?: number;
}

export function configure(config: Config): WHCal {
    const calendar: WHCal = new WHCal(config);
    calendar.init();
    return calendar;
}

/**
 * Calendar class.
 */
export class WHCal {

    private stage: 'START'|'INIT';

    private startDate: SimpleDate;
    private endDate: SimpleDate;

    private firstDate: SimpleDate; // start date of the calendar, usually today

    private offset: number = 0; // month offset to make sure an externally selected date is visible in the widget

    private readonly container:HTMLElement;
    private availabilityUrlString: string; // the URL for the ICAL availability
    // callback functions
    private onStartDate?: (isoDateString: string) => void;
    private onNights?: (nights: number) => void;

    // month panels
    private panels: HTMLDivElement[] = new Array(6);

    private nextButton: HTMLButtonElement;
    private prevButton: HTMLButtonElement;

    public static debug: boolean;

    private cellCache: Map<String, HTMLTableCellElement>; // keep references to the table cells
    private modifiedCells: string[]; // keep track of modified cells for style class updates

    private statusPanel: HTMLDivElement;

    constructor(config: Config) {
        this.container = document.getElementById(config.containerId);
       this.container.classList.add(config.classPrefix || 'whcal'); // root class
        this.availabilityUrlString = config.remote;
        WHCal.debug = config.debug || false;
        
        this.onStartDate = config.onStartDate;
        this.onNights = config.onNights;
        this.cellCache = new Map();
        this.modifiedCells = [];
        this.stage = 'INIT';
        if (config.first) {
            this.firstDate = SimpleDate.parseIsoDate(config.first);
        } else {
            this.firstDate = SimpleDate.parseIsoDate(new Date().toISOString().substring(0, 10));
        }
        this.createCalendar();
    }

    /**
     * Initialise the calendar.
     */
    public init(): void {
        // request the availability from an JSON get request
        if(this.availabilityUrlString) {
            setTimeout(() => this.getAvailabilityRequest(), INITIAL_DELAY);
        }
    }

    /**
     * A div element with panels for each calendar month.
     */
    private createCalendar(): void {
        const clickListener = (e: Event, date: SimpleDate) => {
            e.preventDefault();
            if(this.stage === 'INIT') {
                this.startDate = date;
                this.stage = 'START';
                if(this.onStartDate) {
                    this.onStartDate(this.startDate.isoDateString());
                }
                this.update(this.startDate, this.startDate);
            } else if(this.stage === 'START') {
                this.endDate = date;
                //this.stage = 'END';
                if(this.endDate.isBefore(this.startDate)) {
                    // swap start and end dates
                    const temp: SimpleDate = this.startDate;
                    this.startDate = this.endDate;
                    this.endDate = temp;
                    // call the on checkin to change the start date
                    this.onStartDate(this.startDate.isoDateString());
                }
                if(this.onNights) {
                    this.onNights(this.startDate.diff(this.endDate));
                }
                this.update(this.startDate, this.endDate);
                this.stage = 'INIT';
            } else {
                this.startDate = null;
                this.endDate = null;
                this.stage = 'INIT';
            }
        }
        const today: Date = new Date(Date.now());
        // function to create a table for a month/year
        const createMonthPanel = (m: number, y: number): HTMLTableElement => {
            const startDate: SimpleDate = new SimpleDate(1, m, y);
            const isoWeekDay: number = startDate.isoWeekDay();
            const table: HTMLTableElement = document.createElement('table');
            const thead: HTMLTableSectionElement = table.createTHead();
            const hRow: HTMLTableRowElement = thead.insertRow();
            for (let weekday of DAY_NAMES) { // day name header
                const cell: HTMLTableCellElement = hRow.insertCell();
                cell.textContent = weekday;
            }
            const caption: HTMLTableCaptionElement = table.createCaption();
            caption.textContent = MONTH_NAMES[m] + ' ' + y;
            const tbody: HTMLTableSectionElement = table.createTBody();
            let row: HTMLTableRowElement = tbody.insertRow();
            let i: number = 1 - isoWeekDay;
            for (; i <= 0; i++) {
                // create empty cells for weekdays prior to the first day of the month 
                row.insertCell();
            }
            // This is the active month panel. Valid table cells will have a mouse listener attached.
            for (; i <= SimpleDate.daysInMonth(m, y); i++) {
                const date: SimpleDate = new SimpleDate(i, m, y);
                const cell: HTMLTableCellElement = row.insertCell();
                cell.textContent = i.toString();
                this.cellCache.set(date.isoDateString(), cell);
                if (i === today.getDate() && m === today.getMonth() && y === today.getFullYear()) {
                     // today
                    cell.classList.add('today');
                }
                if (i < today.getDate() && m <= today.getMonth() && y <= today.getFullYear()) {
                    // previous days
                    cell.classList.add('past');
                } else {
                    // click listeners are only attached to valid cells
                    cell.addEventListener('click', (e: MouseEvent) => { clickListener(e, date); });
                }
                if ((i + isoWeekDay) % 7 === 0) row = tbody.insertRow();
            }
            // fill in the remaining cells to make a 6 weeks x 7 days grid
            // to guarantee that every possible calendar has the same number
            // of rows for alignment
            for (; i <= 42 - isoWeekDay; i++) {
                const cell: HTMLTableCellElement = row.insertCell();
                cell.classList.add('date-cell');
                cell.textContent = '';
                if ((i + isoWeekDay) % 7 === 0) row = tbody.insertRow();
            }
            return table;
        }
        let currentMonth: number = this.firstDate.m;
        let currentYear: number = this.firstDate.y;
        for(let i: number = 0; i < this.panels.length; i++) { // create month panels
            const div: HTMLDivElement = document.createElement('div');
            div.classList.add('month-panel', 'month-panel-' + i.toString()); // add class names to the panels
            div.appendChild(createMonthPanel(currentMonth, currentYear));
            this.panels[i] = div;
            // after December wrap around to next year
            if (currentMonth >= Month.December) {
                currentMonth = 0;
                currentYear += 1;
            } else currentMonth++;
        }
        this.createElements();
        // show the first month (offset = 0) 
        this.setOffset(0);
    }

    /**
     * @param offset a month offset from 
     */
    private setOffset(offset: number): void {
        this.offset = offset;
        for(let i: number = 0; i < this.panels.length; i++) {
            if(i === offset || i === offset + 1) { // only show two panels at a time
                this.panels[i].hidden = false;
            } else {
                this.panels[i].hidden = true;
            }
        }
        // control disbale/enable of navigation buttons
        this.prevButton.disabled = (this.offset === 0);
        this.nextButton.disabled = (this.offset === this.panels.length - 2);
    }

    /**
     * Create and assemble all HTML elements.
     */
    private createElements(): void {
        while (this.container.lastChild) this.container.lastChild.remove(); // clear the container
        const controlDiv: HTMLDivElement = document.createElement('div');
        controlDiv.classList.add('nav');

        this.prevButton = document.createElement('button');
        this.prevButton.innerText = '\u25c0'; // \u00ab or \u2770 or \\25c0
        this.prevButton.className = 'btn-calendar-nav';
        this.prevButton.disabled = true;
        this.prevButton.addEventListener('click', (e: MouseEvent) => {
            e.preventDefault();
            if (this.offset > 0) this.offset--;
            this.setOffset(this.offset);
        });
        this.nextButton = document.createElement('button');
        this.nextButton.innerText = '\u25b6'; // \u00bb or \u2771 or \u25b6
        this.nextButton.className = 'btn-calendar-nav';
        this.nextButton.disabled = false;
        this.nextButton.addEventListener('click', (e: MouseEvent) => {
            e.preventDefault();
            if (this.offset < this.panels.length - 1) this.offset++;
            this.setOffset(this.offset);
        })
        controlDiv.appendChild(this.prevButton);
        controlDiv.appendChild(this.nextButton);
        const calendarDiv: HTMLDivElement = document.createElement('div');
        calendarDiv.className = 'calendar';
        for (let i: number = 0; i < this.panels.length; i++) {
            calendarDiv.appendChild(this.panels[i]);
        }
        this.container.appendChild(controlDiv);
        this.container.appendChild(calendarDiv);
        this.statusPanel = document.createElement('div');
        this.statusPanel.classList.add('status');
        this.container.appendChild(this.statusPanel);
    }

    /**
     * Updates the calendar from external date and nights
     * @param startDateString 
     * @param nights
     */
    public externalUpdate(startDateString: string, nights: number): void {
        if (startDateString === null || startDateString.length !== 10) return;
        if (nights < 0 || nights > 28) {
            throw Error(`Invalid number of nights: ${nights} (0 < nights <= 28).`);
        };
        const sdStart: SimpleDate = SimpleDate.parseIsoDate(startDateString);
        if (sdStart == null) {
            throw Error(`Could not parse start date: ${startDateString}.`);
        };
        const sdEnd: SimpleDate = sdStart.add(nights);
        const offset: number = WHCal.monthDiff(sdStart, this.firstDate);
        if(offset >= 0 && offset < this.panels.length) {
            this.setOffset(offset);
        }
        this.update(sdStart, sdEnd);
    }

    /**
     * This method manipu;tes existing table data cells
     * @param startDate 
     * @param endDate 
     */
    private update(startDate: SimpleDate, endDate: SimpleDate): void {
        // remove styles
        for(let key of this.modifiedCells) {
            const cell: HTMLDivElement = this.cellCache.get(key);
            if(cell) {
                cell.classList.remove('select', 'select-start', 'select-end', 'select-middle');
            }
        }
        this.modifiedCells = []; // reset/clear
        // render the table cells
        let date: SimpleDate = startDate;
        while(!date.isAfter(endDate)) {
            const key: string = date.isoDateString();
            const cell: HTMLDivElement = this.cellCache.get(key);
            cell.classList.add('select');
            if(date.equals(startDate)) {
                cell.classList.add('select-start');
            } else if(date.equals(endDate)) {
                cell.classList.add('select-end');
            } else {
                cell.classList.add('select-middle');
            }
            this.modifiedCells.push(key);
            date = date.next();
        }
    }

    /**
     * 
     * @param d2 a SimpleDate
     * @param d1 a SimpleDate
     * @returns the number of days from d1 to d2
     */
    private static monthDiff(d2: SimpleDate, d1: SimpleDate): number {
        var months;
        months = (d2.y - d1.y) * 12;
        months -= d1.m;
        months += d2.m;
        return months <= 0 ? 0 : months;
    }

    /**
     * Set  status
     * @param message a string
     * @param delay is the milliseconds until message clears (defaults to 5000)
     */
    private setStatus(message: string): void {
        this.statusPanel.textContent = message;
    }

     /**
     * Step 1: Get request using the UID as resource locator. This will fire unitsReceived
     * @param uri the user identifier
     */
    protected getAvailabilityRequest(): void {
        const abortController: AbortController = new AbortController();
        const options: RequestInit = {
            method: 'get',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }, // 
            signal: abortController.signal,
        };
        this.fireWait(true); // switch on waiting
        // setting a 10 seconds timeout for this request
        const timeout: NodeJS.Timeout = setTimeout(() => abortController.abort(), ABORT_TIME_MILLIES);
		fetch(this.availabilityUrlString, options).then((response: Response) => {
            if(response.ok) { // OK
                this.setStatus(' last updated ' + response.headers.get('Last-Modified'))
                return response.json();
            } else if(429 === response.status) { // too many requests
                const delay: string = response.headers.get('Retry-After');
                throw new Error('Too many requests. Try after ' + delay + ' seconds');
            } else if(402 === response.status) { // no-content
                throw new Error('The server reported no content.');
            } else if(404 === response.status) { // Page Not Found
                throw new Error('No matching units found. Please check.');
            } else {
                throw new Error('Network error: ' + response.status + ' - ' + response.statusText);
            }
        }).then((json: Availability[]) => {
            this.fireAvailabilityReceived(json);
        }).catch((e: Error) => {
            if('AbortError' === e.name) {
                this.fireError(new Error('Server is not responding - aborting request'));
            } else this.fireError(e);
        }).finally(() => {
            // promise settled (cleared or rejected)
            clearTimeout(timeout);
            this.fireWait(false); // switch off waiting
        });
    }

    fireAvailabilityReceived(availability: Availability[]): void {
        availability.forEach((item: Availability) => {
            const cellDiv: HTMLTableCellElement = this.cellCache.get(item.date);
            if(cellDiv) {
                WHCal.renderCell(cellDiv, item);
            }
        });
    }

    fireError(error: Error): void {
        if(WHCal.debug) {
            console.error('DEBUG: ' + error);
        }
    }

    fireWait(isWaiting: boolean): void {
        if(WHCal.debug) {
            console.log(isWaiting ? 'DEBUG: Start Waiting' : 'DEBUG: End waiting')
        }
    }

    static renderCell(cell: HTMLTableCellElement, item: Availability): void {
        if(!item.avail) {
            cell.classList.add('not-available');
        } else {
            const em: HTMLElement = document.createElement('em');
            em.innerText = cell.textContent;
            cell.textContent = null;
            const tooltipComponents: string[] = [];
            if (item.minNights) tooltipComponents.push(`min ${item.minNights} nights`);
            if (item.rate) tooltipComponents.push(`£${item.rate}`);
            em.setAttribute('data-tooltip', tooltipComponents.join(', '));
            cell.appendChild(em);
        }
    }

}